import React from 'react'
import {Helmet} from 'react-helmet'
import {withPrefix} from 'gatsby'
import useSiteMetadata from './SiteMetadata'

const MetaTags = () => {
  const {title, description, keywords} = useSiteMetadata();

  return (
    <Helmet>
      <html lang="en" />
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="Keywords" content = {keywords} />

      <link
        rel="icon"
        type="image/png"
        href={`${withPrefix('/')}img/favicon.png`}
        sizes="32x32"
      />
      <link
        rel="icon"
        type="image/png"
        href={`${withPrefix('/')}img/favicon.png`}
        sizes="16x16"
      />

      <meta name="theme-color" content="#fff" />

      <meta property="og:type" content="business.business" />
      <meta property="og:title" content={title} />
      <meta property="og:url" content="/" />
      <meta property="og:image" content={`${withPrefix('/')}img/logo.jpg`} />
    </Helmet>
  );
}

export default MetaTags
