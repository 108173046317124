import React, { useRef, useState, useEffect } from 'react'
import VizSensor from 'react-visibility-sensor'
import './styles.scss'
import styled from 'styled-components'
import phoneVideo from '../../../static/img/snowball-footer-vid.mp4'
import phone from '../../../static/img/snowball-phone.png'

type Props = {
  mainText: String,
  subText: String,
  image: any,
}

const iconDict = {
  iOS: 'icon_iOS.svg',
  Android: 'icon_Android.svg',
  'Mac OS': 'icon_Mac.png',
  Windows: 'icon_Windows.png',
  'QR Code': 'icon_QR.svg',
}

const downloadDict = {
  iOS: 'https://apps.apple.com/cn/app/xy-trade/id1209930465?l=en',
  Android: 'https://play.google.com/store/apps/details?id=com.snowballfinance.android',
  'Mac OS': 'https://i.snowballsecurities.com/pc/download/latest/osx_64',
  Windows: 'https://i.snowballsecurities.com/pc/download/latest/windows_64',
}

function DownloadNow({ mainText, subText, image }: Props) {
  const [mouseOver, setMouseOver] = useState('');
  const [isMobile, setIsmobile] = useState(false);
  const handleMouseOver = (item) => setMouseOver(item);
  useEffect(() => {
    setIsmobile(/(iPhone|iPad|iPod|iOS|Android)/i.test(window.navigator.userAgent));
  }, []);
  const videoRef = useRef(null)
  const handleDownload = (item) => {
    const link = document.createElement('a');
    link.href = downloadDict[item];
    link.rel = "noopener noreferrer";
    link.target = "_blank";
    link.click();
    link.remove();
  }
  // const userAgentInfo = window.navigator.userAgent;
  return (
    <div className="download-now" onScrollCapture>
      <div className="container">
        <div className="row mx-auto">
          <div className="col-md-6 col-6 ml-auto d-flex flex-column justify-content-center text-container">
            <h1 className="text-primary display-3">{mainText}</h1>
            <h3 className="big-subtitle text-dark">{subText}</h3>
            <div className="d-flex">
              {isMobile ? (
                <>
                  {['iOS', 'Android', 'Mac OS', 'Windows'].map(item => (
                    <div className="icon-line">
                      <div className="icon-item">
                        <img
                          alt={item}
                          src={require(`../../img/download/${iconDict[item]}`)}
                          onClick={() => handleDownload(item)}
                        />
                        <div className="icon-text">{item}</div>
                      </div>
                      <div className="icon-filled" />
                    </div>
                  ))}
                  <div className="icon-line-qr">
                    <div className="icon-item">
                      <img
                        alt="QR Code"
                        src={require('../../img/download/icon_download_QR.jpg')}
                      />
                    </div>
                    <div className="icon-filled" />
                  </div>
                </>
              ) : (
                  <>
                    {
                      ['iOS', 'Android', 'Mac OS', 'Windows'].map(item => (
                        <div className="icon-line">
                          <div className="icon-item">
                            <img
                              alt={item}
                              src={mouseOver === item ? require('../../img/download/icon_download.svg') : require(`../../img/download/${iconDict[item]}`)}
                              onMouseOver={() => handleMouseOver(item)}
                              onMouseOut={() => handleMouseOver('')}
                              onClick={() => handleDownload(item)}
                            />
                            <div className="icon-text">{item}</div>
                          </div>
                          <div className="icon-filled" />
                        </div>
                      ))
                    }
                    {mouseOver === 'QR Code' ? (
                      <div className="icon-line-qr">
                        <div className="icon-item">
                          <img
                            alt="QR Code"
                            src={require('../../img/download/icon_download_QR.jpg')}
                            onMouseOut={() => handleMouseOver('')}
                          />
                        </div>
                        <div className="icon-filled" />
                      </div>
                    ) : (
                        <div className="icon-line">
                          <div className="icon-item">
                            <img
                              alt="QR Code"
                              src={require('../../img/download/icon_QR.svg')}
                              onMouseOver={() => handleMouseOver('QR Code')}
                            />
                            <div className="icon-text">QR Code</div>
                          </div>
                          <div className="icon-filled" />
                        </div>
                      )}
                  </>
                )}
            </div>
          </div>
          <div className="col-md-4 col-6 mr-auto" style={{ pointerEvents: 'none' }}>
            <VizSensor
              partialVisibility
              onChange={isVisible => {
                console.log(isVisible)
                if (isVisible) {
                  videoRef.current.play()
                }
              }}
            >
              <StyledVideo
                src={phoneVideo}
                poster={phone}
                id="downloadNowPhone"
                ref={videoRef}
              // className="d-none d-md-flex"
              >
                <track />
              </StyledVideo>
            </VizSensor>
          </div>
        </div>
      </div>
    </div>
  )
}

const StyledVideo = styled.video`
  max-width: 80%;
  margin-bottom: 50px;

  @media (max-width: 550px) {
    max-width: 80%;
    height: 250px;
    object-position: top;
    object-fit: contain;
  }
`

export default DownloadNow
